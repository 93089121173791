<template>
  <div class="footer-brand">
    <router-link
      class="footer-brand__link"
      :to="{name: 'home'}"
    >
      <Logo5 class="footer-brand__logo" />
    </router-link>
    <TickerTxt
      class="footer-brand__ticker"
      :txts="tickerTxts"
    />
  </div>
</template>

<script>
import Logo5 from "@/components/svg/Logo5.vue"
import TickerTxt from "@/components/TickerTxt.vue"

export default {
  components: {
    Logo5,
    TickerTxt
  },

  props: {
    tickerTxts: {
      type: Array, // String[]
      required: true
    }
  }
}
</script>

<styles lang="scss">
@use "svg";
@use "vars";

.footer-brand__link {
  display: block;
  margin: 0 auto 0.5em auto;
  $d: 5em;
  width: $d;
  height: $d;
}

.footer-brand__logo {
  display: block;
  margin: 0 auto 0.5em auto;
  $d: 100%;
  width: $d;
  height: $d;
  @include svg.logo-5(vars.$gold);
}

.footer-brand__ticker {
  font-size: 1.5em;
  text-align: center;
  font-family: vars.$font-fam-deco;
  color: vars.$gold;
}
</styles>
