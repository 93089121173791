<template>
  <div class="img-hero-overlay">
    <div class="img-hero-overlay__top">
      <div class="img-hero-overlay__slogan">
        <transition
          name="fade"
          mode="out-in"
        >
          <!-- Without :key the rendered node is re-used (no transition). -->
          <span :key="slogan">{{ slogan }}</span>
        </transition>
      </div>
    </div>
    <div class="img-hero-overlay__btm">
      <div class="img-hero-overlay__cntwrap">
        <MoonBg
          v-for="i in idxs"
          :key="i"
          class="img-hero-overlay__cnt"
          :moon-opacity="i === shownIdx ? 0.9 : 0.5"
          @click.native="$emit('img-hero-overlay-selectidx', i)"
        >
          <span>{{ i + 1 }}</span>
        </MoonBg>
      </div>
      <div class="img-hero-overlay__rsv">
        <BtnRsv class="img-hero-overlay__btn-rsv" />
      </div>
    </div>
  </div>
</template>

<script>
import BtnRsv from "@/components/BtnRsv"
import MoonBg from "@/components/MoonBg.vue"
import {range} from "lodash-es"

export default {
  components: {
    BtnRsv,
    MoonBg
  },

  props: {
    numImgs: {
      type: Number,
      required: true
    },
    shownIdx: {
      type: Number,
      required: true
    },
    slogan: {
      type: String,
      default: ""
    }
  },

  computed: {
    idxs() {
      return range(this.numImgs)
    }
  }
}
</script>

<styles lang="scss">
@use "sass:math";

@use "img-hero";
@use "trans";
@use "vars";

.img-hero-overlay {
  padding-top: 2.5em;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  justify-content: space-between;
}

.img-hero-overlay__slogan {
  text-align: center;
  span {
    font-size: 1.8em;
    font-family: vars.$font-fam-deco;
    color: vars.$gold;
    text-transform: uppercase;
    /* Factor 0.3 obtained via try-and-error (what looks good). */
    @include trans.fade($t: (img-hero.$trans-fade-t * 0.3));
  }

  @media (max-width: vars.$break-mobile) {
    span {
      font-size: 1.5em;
    }
  }
}

.img-hero-overlay__btm {
  display: grid;
  grid-template:
    "cntwrap rsv" auto /
    1fr 1fr;
  grid-gap: 2em;
  padding: 1em vars.$body-padding-h;

  @media (max-width: vars.$break-mobile) {
    grid-template:
      "rsv" auto
      "cntwrap" auto /
      1fr;
    grid-gap: 1em;
    padding-bottom: 0.5em;
  }
}

.img-hero-overlay__cntwrap {
  grid-area: cntwrap;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 math.div(vars.$body-padding-h, 2);

  @media (max-width: vars.$break-mobile) {
    justify-content: center;
  }
}

.img-hero-overlay__cnt {
  $d: 2em;
  width: $d;
  height: $d;
  margin-left: 0.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  span {
    font-family: vars.$font-fam-deco;
    font-size: 1.2em;
  }

  @media (max-width: vars.$break-mobile) {
    margin: 0 0.25em;
  }
}

.img-hero-overlay__rsv {
  grid-area: rsv;
  text-align: right;

  @media (max-width: vars.$break-mobile) {
    text-align: center;
  }
}

.img-hero-overlay__btn-rsv {
  opacity: 0.9;
}
</styles>
