<template>
  <LinkRsv class="btn-rsv">
    <slot>
      Reservieren
    </slot>
  </LinkRsv>
</template>

<script>
import LinkRsv from "@/components/LinkRsv.vue"

export default {
  components: {
    LinkRsv
  }
}
</script>

<styles lang="scss">
@use "vars";

.btn-rsv {
  display: inline-block;
  padding: 0.5em 1em;
  background-color: vars.$beige;
  color: vars.$white;
  text-transform: uppercase;
  text-decoration: none;
}
</styles>
