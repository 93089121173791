import home from "@/store/modules/home.js"
import togoTerms from "@/store/modules/togoTerms.js"
import Vue from "vue"
import Vuex from "vuex"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    home,
    togoTerms
  }
})
