<template>
  <nav class="nav-bar">
    <ul class="nav-bar__ul">
      <li
        v-for="x in topics.left"
        :key="x"
        class="nav-bar__li"
      >
        <span
          class="nav-bar__topic"
          @click="onTopicClick(x)"
        >
          {{ x }}
        </span>
      </li>

      <li
        class="nav-bar__logowrap"
        :class="{'nav-bar__logowrap--bgcolor': selectedTopic}"
      >
        <router-link
          class="nav-bar__logo"
          :to="{name: 'home'}"
        >
          <Logo5 />
        </router-link>
      </li>

      <li
        v-for="x in topics.right"
        :key="x"
        class="nav-bar__li"
      >
        <span
          class="nav-bar__topic"
          @click="onTopicClick(x)"
        >
          {{ x }}
        </span>
      </li>
    </ul>
    <NavBarSub
      v-show="selectedTopic"
      :title="selectedTopic"
      :links="topicLinks"
      @nav-bar-sub-close="selectedTopic = ''"
    />
  </nav>
</template>

<script>
import Logo5 from "@/components/svg/Logo5.vue"
import NavBarSub from "@/components/NavBarSub.vue"
import {navLinks, navTopics} from "@/models/LinkData.js"

export default {
  components: {
    Logo5,
    NavBarSub
  },

  data() {
    return {
      links: navLinks,
      topics: {
        left: navTopics.slice(0, 3),
        right: navTopics.slice(3, 6)
      },
      selectedTopic: ""
    }
  },

  computed: {
    topicLinks() {
      return this.selectedTopic ? this.links[this.selectedTopic] : []
    }
  },

  watch: {
    $route() {
      this.selectedTopic = ""
    }
  },

  methods: {
    /** @param {String} topic */
    onTopicClick(topic) {
      if (this.selectedTopic === topic) {
        this.selectedTopic = ""
      } else {
        this.selectedTopic = topic
      }
    }
  }
}
</script>

<styles lang="scss">
@use "nav";
@use "svg";
@use "txt";
@use "vars";
@use "z-index";

.nav-bar {
  position: relative; // make it containing block of NavBarSub
  color: vars.$white;
}

.nav-bar__ul {
  display: grid;
  grid-template-columns: repeat(3, 1fr) nav.$height repeat(3, 1fr);
  grid-template-rows: nav.$height;
  grid-gap: 1em;
  padding: 0 vars.$body-padding-h;
  background-color: nav.$color-bg;
}

.nav-bar__li {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 0;
}

.nav-bar__topic {
  text-transform: uppercase;
  font-family: vars.$font-fam-deco;
  cursor: pointer;
  @include txt.oneliner;
}

.nav-bar__logo {
  position: relative;
  top: 50%;
  $d: 100%;
  width: $d;
  height: $d;
  z-index: z-index.$nav-logo;

  svg {
    @include svg.logo-5(vars.$gold);
  }
}

.nav-bar__logowrap--bgcolor {
  .logo-5 {
    background-color: nav.$color-bg;
  }
}
</styles>
