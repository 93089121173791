<template>
  <div class="nav-mobile-links">
    <div class="nav-mobile-links__topic">
      {{ topic }}
    </div>
    <ul class="nav-mobile-links__ul">
      <li
        v-for="x in links"
        :key="x.txt"
        class="nav-mobile-links__li"
      >
        <router-link
          v-if="x.isLinkData"
          :to="x.route"
        >
          {{ x.txt }}
        </router-link>
        <a
          v-if="x.isLinkDataExt"
          :href="x.href"
          :target="x.target"
        >
          {{ x.txt }}
        </a>
      </li>
    </ul>
    <NucleoGUndo25
      class="nav-mobile-links__cleartopic"
      @click.native="$emit('nav-mobile-links-cleartopic')"
    />
  </div>
</template>

<script>
import LinkData from "@/models/LinkData.js"
import LinkDataExt from "@/models/LinkDataExt.js"
import NucleoGUndo25 from "@/components/svg/NucleoGUndo25.vue"

export default {
  components: {
    NucleoGUndo25
  },

  props: {
    topic: {
      type: String,
      required: true
    },
    links: {
      type: Array,
      required: true,
      validator: arr => arr.every(x => {
        return x instanceof LinkData || x instanceof LinkDataExt
      })
    }
  }
}
</script>

<styles lang="scss">
@use "svg";
@use "vars";

.nav-mobile-links {
  padding: 3.5em vars.$body-padding-h 2em vars.$body-padding-h;
}

.nav-mobile-links__topic {
  font-size: 1.5em;
  text-align: center;
  color: vars.$gold;
  font-family: vars.$font-fam-deco;
  text-transform: uppercase;
}

.nav-mobile-links__ul {
  margin-top: 0.5em;
}

.nav-mobile-links__li {
  margin-top: 0.5em;
  text-align: center;

  a {
    font-size: 1.2em;
    font-family: vars.$font-fam-deco;
    text-transform: uppercase;
    text-decoration: none;
    &.router-link-exact-active {
      color: vars.$gold;
    }
  }
}

.nav-mobile-links__cleartopic {
  display: block;
  $d: 1.5em;
  width: $d;
  height: $d;
  margin: 1em auto 0 auto;
  cursor: pointer;
  @include svg.nucleo-g-undo-25(vars.$white);
}
</styles>
