import LinkDataExt from "@/models/LinkDataExt.js"

export default class LinkData {
  /**
   * @param {String} txt
   * @param {Route} route - vue-router Route object
   */
  constructor(txt, route) {
    this.isLinkData = true // instanceof LinkData in template would be clumsy
    this.txt = txt
    this.route = route
  }
}

/*
export const navLinksTogo = [
  new LinkData("Abendkarte & Dinner Boxen to go", {
    name: "schmecken",
    params: {name: "dinner-to-go"}
  }),
  new LinkData("Sternenzauber to go", {
    name: "schmecken",
    params: {name: "sternenzauber"}
  }),
  new LinkData("Ostern im 5", {
    name: "schmecken",
    params: {name: "ostern"}
  }),
  new LinkData("Sushi to go", {
    name: "schmecken",
    params: {name: "sushi-to-go"}
  })
  new LinkData("Weihnachten & Silvester im 5", {
    name: "schmecken",
    params: {name: "weihnachten-silvester-im-5"}
  })
]
*/

/*
const navLinkComingSoon = new LinkData("In Kürze verfügbar", {
  name: "home"
})
*/

// navLinks contains LinkData[Ext] per topic.
export const navLinks = {
  // @/src/components/NavBar depends on data (size & order) in this object.
  Schmecken: [
    new LinkData("Gourmet Restaurant", {
      name: "schmeckenGourmetRestaurant"
    }),
    new LinkData("Bar/Restaurant", {
      name: "schmeckenBarRestaurant"
    }),
    new LinkData("Lunch", {
      name: "schmeckenLunch"
    }),
    new LinkData("Frühstück", {
      name: "schmeckenFruehstueck"
    })
  ],
  Sehen: [
    new LinkData("Wohnraum", {
      name: "sehenWohnraum"
    }),
    new LinkData("Essraum", {
      name: "sehenEssraum"
    }),
    new LinkData("Freiraum", {
      name: "sehenFreiraum"
    }),
    new LinkData("Team", {
      name: "sehenTeam"
    }),
    new LinkData("Film", {
      name: "sehenFilm"
    })
  ],
  Fühlen: [
    new LinkData("Samstag", {
      name: "fuehlenSamstag"
    })
    /*
    new LinkData("Sonntag", {
      name: "fuehlenSonntag"
    })
    new LinkData("Events", {
      name: "fuehlen",
      params: {name: "events"}
    })
    */
  ],
  Gutscheine: [
    new LinkDataExt({
      txt: "Zum Gutscheinshop",
      href: "https://www.yovite.com/Restaurant-Gutschein-R-53078256.html?REF=REST",
      target: "_blank"
    })
  ],
  Veranstaltungen: [
    new LinkData("Ihre Veranstaltung im 5", {
      name: "veranstaltungenIhreVeranstaltungIm5"
    }),
    new LinkData("Heiraten im 5", {
      name: "veranstaltungenHeiratenIm5"
    }),
    new LinkData("Private Cooking", {
      name: "veranstaltungenPrivateCooking"
    }),
    new LinkData("Kochkurse", {
      name: "veranstaltungenKochkurse"
    })
  ],
  Kontakt: [
    new LinkData("Kontakt", {
      name: "kontakt"
    }),
    new LinkData("Reservieren", {
      name: "reservieren"
    }),
    new LinkDataExt({
      txt: "Newsletter Anmeldung",
      href: "https://seu1.cleverreach.com/f/73903-145405",
      target: "_blank"
    }),
    new LinkData("Presse", {
      name: "presse"
    }),
    new LinkData("Impressum", {
      name: "impressum"
    }),
    new LinkData("Datenschutz", {
      name: "datenschutz"
    })
    /*
    new LinkData("Newsletter", {
      name: "kontakt",
      params: {name: "newsletter"}
    }),
    */
  ]
}

export const navTopics = Object.keys(navLinks)

export const urlsExt = Object.freeze({
  facebook: "https://de-de.facebook.com/leidenschaftlich",
  instagram: "https://www.instagram.com/5_restaurant_stuttgart/",
  lunchguide: "https://lunchguide.de/restaurants/de/stuttgart/5"
})
