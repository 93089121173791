<template>
  <div class="footer-small">
    <FooterBrand :ticker-txts="tickerTxts" />
    <ul class="footer-small__ul">
      <FooterSmallTopic
        v-for="t in topics"
        :key="t"
        :topic="t"
        :links="links[t]"
      />
      <FooterProfiles class="footer-small__profiles" />
    </ul>
  </div>
</template>

<script>
import FooterBrand from "@/components/FooterBrand.vue"
import FooterProfiles from "@/components/FooterProfiles.vue"
import FooterSmallTopic from "@/components/FooterSmallTopic.vue"
import {navLinks, navTopics} from "@/models/LinkData.js"

export default {
  components: {
    FooterBrand,
    FooterProfiles,
    FooterSmallTopic
  },

  props: {
    tickerTxts: {
      type: Array, // String[]
      required: true
    }
  },

  data() {
    return {
      links: navLinks,
      topics: navTopics
    }
  }
}
</script>

<styles lang="scss">
@use "nav";
@use "vars";

.footer-small {
  padding: 2em vars.$body-padding-h;
  color: nav.$color;
  background-color: nav.$color-bg;
}

.footer-small__ul {
  margin-top: 1em;
}

.footer-small__profiles {
  margin-top: 2em;
}
</styles>
