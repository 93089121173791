<template>
  <div class="nav-mobile-topics">
    <ul class="nav-mobile-topics__ul">
      <li
        v-for="t in topics"
        :key="t"
        class="nav-mobile-topics__li"
      >
        <span
          @click="$emit('nav-mobile-topics-select', t)"
        >
          {{ t }}
        </span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    topics: {
      type: Array, // String[]
      required: true
    }
  }
}
</script>

<styles lang="scss">
@use "vars";

.nav-mobile-topics {
  padding: 3.5em vars.$body-padding-h 2em vars.$body-padding-h;
}

.nav-mobile-topics__li {
  margin-top: 0.5em;
  text-align: center;

  span {
    font-size: 1.2em;
    font-family: vars.$font-fam-deco;
    text-transform: uppercase;
    cursor: pointer;
  }
}
</styles>
