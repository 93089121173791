/*
 * fwdIdxCircular forwards idx by n circularly
 * @param {Number} idx
 * @param {Number} n
 * @param {Number} len
 * @returns {Number} resulting idx
 */
export function fwdIdxCircular(idx, n, len) {
  n = n % len // fix n > len
  let x = idx + n
  if (x >= len) {
    x -= len
  }
  return x
}
