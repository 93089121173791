import {cloneDeep, merge} from "lodash-es"

const imgDataDefaultOpts = {
  slogan: "",
  bgPos: "" // background-position for background-image
}

export default class ImgData {
  /**
   * @param {String} src
   * @param {String} alt
   * @param {Object} [opts={}] - options, see imgDataDefaultOpts
   */
  constructor(src, alt, opts = {}) {
    this.src = src
    this.alt = alt
    this.opts = merge(cloneDeep(imgDataDefaultOpts), opts)
  }

  /*
   * arrID returns a string identifieng an ImgData[] (independent of order).
   * @param {ImgData[]} arr
   * @returns {String}
   */
  // TODO add class ImgDataArr with getter ID (will be cached)
  static arrID(arr) {
    const srcs = arr.map(x => x.src)
    srcs.sort()
    return srcs.toString()
  }
}
