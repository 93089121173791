<template>
  <div id="app">
    <AppNav />
    <router-view />
    <AppFooter />
    <CookieBanner />
  </div>
</template>

<script>
import AppFooter from "@/components/AppFooter.vue"
import AppNav from "@/components/AppNav.vue"
import CookieBanner from "@/components/CookieBanner.vue"

export default {
  components: {
    AppFooter,
    AppNav,
    CookieBanner
  }
}
</script>

<style lang="scss">
@use "~normalize.css/normalize";
@use "styles";
</style>
