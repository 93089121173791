<template>
  <div class="footer-profiles">
    <a
      v-for="p in profiles"
      :key="p.href"
      :href="p.href"
      target="_blank"
    >
      <component :is="p.icon" />
    </a>
  </div>
</template>

<script>
import NucleoGFacebookPlain from "@/components/svg/NucleoGFacebookPlain.vue"
import NucleoGInstagram from "@/components/svg/NucleoGInstagram.vue"
import {urlsExt} from "@/models/LinkData.js"

export default {
  components: {
    NucleoGFacebookPlain,
    NucleoGInstagram
  },

  data() {
    return {
      profiles: [
        {icon: "NucleoGInstagram", href: urlsExt.instagram},
        {icon: "NucleoGFacebookPlain", href: urlsExt.facebook}
      ]
    }
  }
}
</script>

<styles lang="scss">
@use "nav";
@use "svg";
@use "vars";

.footer-profiles {
  display: flex;
  align-items: center;
  justify-content: center;

  $d: 2em;

  a {
    margin-left: 1.5em;
    &:first-child {
      margin-left: 0;
    }
  }

  svg {
    width: $d;
    height: $d;

    $col: nav.$color;
    &.nucleo-g-facebook-plain {
      @include svg.nucleo-g-facebook-plain($col);
    }
    &.nucleo-g-instagram {
      @include svg.nucleo-g-instagram($col);
    }
  }
}
</styles>
