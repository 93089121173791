import Vue from "vue"
import VueApollo from "vue-apollo"
import ApolloClient from "apollo-boost"
import App from "./App.vue"
import router from "./router"
import store from "./store"

// Setup apollo (to connect to cms).
Vue.use(VueApollo)
const apolloClient = new ApolloClient({
  uri: process.env.VUE_APP_GRAPHQL_URL // should be absolute
})
const apolloProvider = new VueApollo({
  defaultClient: apolloClient
})

new Vue({
  router,
  store,
  apolloProvider,
  render: h => h(App)
}).$mount("#app")
