export default class LinkDataExt {
  /**
   * @param {Object} p - params
   * @param {String} p.txt
   * @param {String} p.href
   * @param {String|undefined} [p.target]
   */
  constructor(p) {
    this.isLinkDataExt = true // instanceof LinkDataExt in template is clumsy
    Object.assign(this, p)
  }
}
