<template>
  <CookieBox
    :consent-store-source="storeSource"
    :privacy-link="{name: 'datenschutz'}"
  />
</template>

<script>
import {CookieBox} from "vue-cookie-box"
import "vue-cookie-box/dist/vue-cookie-box.css"

export default {
  components: {
    CookieBox
  },

  data() {
    return {
      storeSource: 1 // localStorage
    }
  }
}
</script>

<style lang="scss">
@use "vars";
@use "z-index";

.cookie-box-banner {
  background-color: vars.$beige;
  color: vars.$white;
  z-index: z-index.$cookie-banner;
}
</style>
