import Vue from "vue"
import VueRouter from "vue-router"
import Home from "../views/Home.vue"

Vue.use(VueRouter)

/**
 * Supported meta attributes:
 * @param {String} title - text for document's <title>
 * @param {String} description - content for <meta name="description" content="...">
 * @param {String} [schmeckenKey] - to retrieve content for views/Schmecken
 * @param {String} [sehenKey] - to retrieve content for views/Sehen
 * @param {String} [fuehlenKey] - to retrieve content for views/Fuehlen
 * @param {String} [veranstaltungenKey] - to retrieve content for views/Veranstaltung
 */

// Keep default{Title, Description} in sync with index.html.
const defaultTitle = "5 Gourmet Restaurant - Bar - Café - Sterneküche in Stuttgart Mitte"
const defaultDescription = "Ob Dinner, Frühstück, Lunch oder Asia/Sushi Menu - das 5 Gourmet Restaurant im Herzen von Stuttgart ist immer außergewöhnlich anders."

/**
 * Not using route params like schmecken/:name to allow for SEO friendly URLs,
 * i.e. /lunch instead of /schmecken/lunch. Content to render in views/Schmecken
 * is retrieved via route's meta.schmeckenKey.
 *
 * As a result, each route has an explicit RouteRecord. This lends itself to
 * defining SEO title and meta attributes directly in route.meta.
 *
 * Why /lunch is better for SEO than /schmecken/lunch:
 * - keyword lunch is higher in URL path
 * - no separate page for /schmecken required
 */
const routes = [
  {
    path: "/",
    name: "home",
    meta: {
      title: defaultTitle,
      description: defaultDescription
    },
    component: Home
  },

  // views/Schmecken
  {
    path: "/gourmet-restaurant-sterne-menu",
    name: "schmeckenGourmetRestaurant",
    meta: {
      title: "Sterne Menu in bis zu 7 Gängen im Gourmet Restaurant",
      description: "Sternekoch Alexander Dinter verzaubert Sie mit seinem Menu Passion in bis zu sieben Gängen. Weltküche mit Wein- oder Saftbegleitung.",
      schmeckenKey: "gourmet-restaurant"
    },
    component: () => import(/* webpackChunkName: "schmecken" */ "@/views/Schmecken.vue")
  },
  {
    path: "/bar-restaurant-feinschmecker-asia-menu",
    name: "schmeckenBarRestaurant",
    meta: {
      title: "Asia/Sushi Menu oder Feinschmecker Menu in zwei bis drei Gängen",
      description: "Abends verwöhnen wir Sie in der 5 Bar/Restaurant mit Asia/Sushi Menu sowie Feinschmecker Menu in zwei oder drei Gängen.",
      schmeckenKey: "bar-restaurant"
    },
    component: () => import(/* webpackChunkName: "schmecken" */ "@/views/Schmecken.vue")
  },
  {
    path: "/lunch",
    name: "schmeckenLunch",
    meta: {
      title: "Kreativer Mittagstisch und Business Lunch Menu im Herzen von Stuttgart",
      description: "Eine kulinarische Weltreise zum Mittagessen. Gesunde Ernährung mit frischen, hochwertigen Lebensmitteln ist die Basis unserer Mittagsküche.",
      schmeckenKey: "lunch"
    },
    component: () => import(/* webpackChunkName: "schmecken" */ "@/views/Schmecken.vue")
  },
  {
    path: "/fruehstueck",
    name: "schmeckenFruehstueck",
    meta: {
      title: "Frühstück in entspannter Atmosphäre. Alle Zutaten in Bio oder Demeter Qualität.",
      description: "5 Gourmet Frühstück - Mehr als das tägliche Brot. Alle Produkte in Bio oder Demeter Qualität aus regionaler und nachhaltiger Produktion.",
      schmeckenKey: "fruehstueck"
    },
    component: () => import(/* webpackChunkName: "schmecken" */ "@/views/Schmecken.vue")
  },

  // views/Sehen
  {
    path: "/wohnraum",
    name: "sehenWohnraum",
    meta: {
      title: "5 Wohnraum - Wir verwöhnen Sie zu jeder Tageszeit in unserer Bar/Restaurant",
      description: "In unserer Bar/Restaurant im Erdgeschoss machen alle Disziplinen Spaß: Frühstück, Lunch, Kaffee oder am Abend Feinschmecker sowie Asia/Sushi Menu.",
      sehenKey: "wohnraum"
    },
    component: () => import(/* webpackChunkName: "sehen" */ "@/views/Sehen.vue")
  },
  {
    path: "/essraum",
    name: "sehenEssraum",
    meta: {
      title: "5 Essraum - Genuss für Augen und Gaumen auf höchstem Niveau",
      description: "Unser Gourmet Restaurant im Obergeschoss befindet sich in den Räumlichkeiten des ersten Stuttgarter Bahnhofs. Hier findet das gastronomische 21. Jahrhundert statt.",
      sehenKey: "essraum"
    },
    component: () => import(/* webpackChunkName: "sehen" */ "@/views/Sehen.vue")
  },
  {
    path: "/freiraum-terrasse",
    name: "sehenFreiraum",
    meta: {
      title: "5 Freiraum - Unsere Terrasse im Herzen von Stuttgart - Nähe Schlossplatz",
      description: "Geniessen Sie zum Frühstück, Lunch oder Abendessen sonnige Stunden in Stuttgart Mitte.",
      sehenKey: "freiraum"
    },
    component: () => import(/* webpackChunkName: "sehen" */ "@/views/Sehen.vue")
  },
  {
    path: "/team",
    name: "sehenTeam",
    meta: {
      title: "Wir sind Gastgeber aus Leidenschaft.",
      description: "Wir verwöhnen Sie jeden Tag von Herzen. Anja Dold, Michael Zeyer und das gesamte 5 Team freuen sich auf Ihren Besuch.",
      sehenKey: "team"
    },
    component: () => import(/* webpackChunkName: "sehen" */ "@/views/Sehen.vue")
  },
  {
    path: "/film",
    name: "sehenFilm",
    meta: {
      title: "Erfahren Sie mehr über uns in unseren Clips",
      description: "Die Welt des 5 in unseren Videos kennen lernen.",
      sehenKey: "film"
    },
    component: () => import(/* webpackChunkName: "sehen" */ "@/views/Sehen.vue")
  },

  // views/Fuehlen
  {
    path: "/klassiker-der-musikgeschichte",
    name: "fuehlenSamstag",
    meta: {
      title: "Samstags: frisch gewaschene Klassiker der Musikgeschichte",
      description: "Ab 21 Uhr mit DJ Soulstar, DJ Raul Rincon, DJ Wim Gutmann",
      fuehlenKey: "samstag"
    },
    component: () => import(/* webpackChunkName: "fuehlen" */ "@/views/Fuehlen.vue")
  },
  {
    path: "/fruehstuecks-film-matinee",
    name: "fuehlenSonntag",
    meta: {
      title: "Frühstücks Film Matinee - Legendäre Klassiker der Filmgeschichte",
      description: "Jeden ersten Sonntag im Monat: Frühstück ab 10 Uhr, Filmvorführung ab 11 Uhr.",
      fuehlenKey: "sonntag"
    },
    component: () => import(/* webpackChunkName: "fuehlen" */ "@/views/Fuehlen.vue")
  },

  // views/Veranstaltung
  {
    path: "/ihre-veranstaltung-im-5",
    name: "veranstaltungenIhreVeranstaltungIm5",
    meta: {
      title: "Ihre Firmenfeier, Jubiläum oder exklusives Event im 5",
      description: "Wir machen Ihre Veranstaltung zum besonderen Erlebnis. Von flying Service bis gesetztes Dinner - Unser Sternekoch und sein Team verwöhnen Sie und Ihre Gäste.",
      veranstaltungenKey: "ihre-veranstaltung-im-5"
    },
    component: () => import(/* webpackChunkName: "veranstaltung" */ "@/views/Veranstaltung.vue")
  },
  {
    path: "/heiraten-im-5",
    name: "veranstaltungenHeiratenIm5",
    meta: {
      title: "Heiraten in der Atmosphäre von Gourmet Restaurant und kunstgerechter Bar",
      description: "Ihre Hochzeit im 5: ein stilvolles Hochzeitsdinner oder eine unvergessliche Hochzeitsparty - wir erfüllen jeden Wunsch.",
      veranstaltungenKey: "heiraten-im-5"
    },
    component: () => import(/* webpackChunkName: "veranstaltung" */ "@/views/Veranstaltung.vue")
  },
  {
    path: "/private-cooking-sternekoch-bei-ihnen-zuhause",
    name: "veranstaltungenPrivateCooking",
    meta: {
      title: "Private Cooking mit Sternekoch Alexander Dinter",
      description: "Buchen Sie Sternekoch Alexander Dinter für Ihre Veranstaltung zu Hause",
      veranstaltungenKey: "private-cooking"
    },
    component: () => import(/* webpackChunkName: "veranstaltung" */ "@/views/Veranstaltung.vue")
  },
  {
    path: "/kochkurse-mit-sternekoch",
    name: "veranstaltungenKochkurse",
    meta: {
      title: "Exklusiver Kochkurs in Stuttgart mit Sternekoch Alexander Dinter",
      description: "5 Küchenchef Alexander Dinter kocht Spitzenkulinarik mit einer Gruppe von 12 Personen.",
      veranstaltungenKey: "kochkurse"
    },
    component: () => import(/* webpackChunkName: "veranstaltung" */ "@/views/Veranstaltung.vue")
  },

  {
    path: "/kontakt",
    name: "kontakt",
    meta: {
      title: "Kontakt - Wir freuen uns auf Ihre Anfrage",
      description: "Hier finden Sie unsere Kontaktdaten."
    },
    component: () => import(/* webpackChunkName: "kontakt" */ "@/views/Kontakt.vue")
  },
  {
    path: "/reservieren",
    name: "reservieren",
    meta: {
      title: "Online Reservierung - Sichern Sie sich jetzt Ihren Tisch",
      description: "Reservieren Sie bei der Nr. 1 der Kundenbewertungen von TripAdvisor 2019 und 2020 in Deutschland bei den gehobenen Restaurants."
    },
    component: () => import(/* webpackChunkName: "reservieren" */ "@/views/Reservieren.vue")
  },
  {
    path: "/presse",
    name: "presse",
    meta: {
      title: "Das 5 in der Presse",
      description: "Lesen Sie nach, was die Presse über uns berichtet."
    },
    component: () => import(/* webpackChunkName: "presse" */ "@/views/Presse.vue")
  },
  {
    path: "/impressum",
    name: "impressum",
    meta: {
      title: "5 - Impressum",
      description: "Hier finden Sie unser Impressum."
    },
    component: () => import(/* webpackChunkName: "impressum" */ "@/views/Impressum.vue")
  },
  {
    path: "/datenschutz",
    name: "datenschutz",
    meta: {
      title: "5 - Datenschutz",
      description: "Hier finden Sie unsere Datenschutzerklärung."
    },
    component: () => import(/* webpackChunkName: "datenschutz" */ "@/views/Datenschutz.vue")
  }
  // TODO add notfound catchall route to prevent history-mode caveat.
  // https://router.vuejs.org/guide/essentials/history-mode.html#caveat
]

function scrollBehavior(to, from, savedPosition) {
  if (savedPosition) {
    // It's a popstate navigation (back/forward button).
    return savedPosition
  } else {
    return {x: 0, y: 0}
  }
}

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior
})

router.afterEach((to, _from) => {
  // Set attributes relevant for SEO.
  //
  // nextTick is required to keep browser history intact, see
  // https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  Vue.nextTick(() => {
    // Set <title>.
    let title = to.meta.title
    if (!title) {
      title = defaultTitle
      // eslint-disable-next-line no-console
      console.warn(`route ${to.name}.meta misses title. setting default.`)
    }
    document.title = title

    // Set <meta name="description" content="...">.
    let descr = to.meta.description
    if (!descr) {
      descr = defaultDescription
      // eslint-disable-next-line no-console
      console.warn(`route ${to.name}.meta misses description. setting default.`)
    }
    const descrEl = document.querySelector("head meta[name='description'")
    if (descrEl) {
      descrEl.setAttribute("content", descr)
    } else {
      // eslint-disable-next-line no-console
      console.warn("missing <meta name='description'> element")
    }
  })
})

export default router
