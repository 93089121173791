// initial state
const state = {
  showIntroScreen: true // in store to across routes
}

const getters = {}

const actions = {}

const mutations = {
  setShowIntroScreen(state, b) {
    state.showIntroScreen = b
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
