<template>
  <nav class="footer-big">
    <FooterBrand :ticker-txts="tickerTxts" />
    <div
      v-for="(ts,i) in topicGrids"
      :key="i"
      class="footer-big__grid"
    >
      <FooterBigCell
        v-for="t in ts"
        :key="t"
        :topic="t"
        :links="links[t]"
      />
    </div>
    <FooterProfiles />
  </nav>
</template>

<script>
import FooterBigCell from "@/components/FooterBigCell.vue"
import FooterBrand from "@/components/FooterBrand.vue"
import FooterProfiles from "@/components/FooterProfiles.vue"
import {navLinks, navTopics} from "@/models/LinkData.js"

export default {
  components: {
    FooterBigCell,
    FooterBrand,
    FooterProfiles
  },

  props: {
    tickerTxts: {
      type: Array, // String[]
      required: true
    }
  },

  data() {
    return {
      links: navLinks,
      topicGrids: [
        navTopics.slice(0, 3),
        navTopics.slice(3, 6)
      ]
    }
  }
}
</script>

<styles lang="scss">
@use "nav";
@use "vars";

.footer-big {
  padding: 2em vars.$body-padding-h;
  color: nav.$color;
  background-color: nav.$color-bg;
}

.footer-big__grid {
  margin: 2em 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto;
  grid-gap: 2em;
}
</styles>
