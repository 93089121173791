<template>
  <div class="app-nav">
    <NavBar class="app-nav__nav-bar" />
    <NavMobile class="app-nav__nav-mobile" />
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue"
import NavMobile from "@/components/NavMobile.vue"

export default {
  components: {
    NavBar,
    NavMobile
  }
}
</script>

<styles lang="scss">
@use "vars";

.app-nav__nav-bar {
  @media (max-width: vars.$break-mobile) {
    display: none;
  }
}

.app-nav__nav-mobile {
  @media (min-width: vars.$break-mobile) {
    display: none;
  }
}
</styles>
