<template>
  <div class="nav-mobile">
    <div class="nav-mobile__grid">
      <div class="nav-mobile__side">
        <component
          :is="showSub ? 'NucleoOERemove' : 'NucleoGMenu8'"
          class="nav-mobile__nucleo"
          @click.native="showSub = !showSub"
        />
      </div>
      <div class="nav-mobile__mid">
        <router-link
          class="nav-mobile__logowrap"
          :class="{'nav-mobile__logowrap--show-sub': showSub}"
          :to="{name: 'home'}"
        >
          <Logo5 />
        </router-link>
      </div>
      <div class="nav-mobile__side nav-mobile__side--right">
        <LinkRsv class="nav-mobile__iconlink">
          <NucleoGTable class="nav-mobile__nucleo" />
        </LinkRsv>
      </div>
    </div>

    <!-- Re-rendering (v-if) NavMobileSub to reset state. -->
    <NavMobileSub v-if="showSub" />
  </div>
</template>

<script>
import LinkRsv from "@/components/LinkRsv.vue"
import Logo5 from "@/components/svg/Logo5.vue"
import NavMobileSub from "@/components/NavMobileSub.vue"
import NucleoOERemove from "@/components/svg/NucleoOERemove.vue"
import NucleoGMenu8 from "@/components/svg/NucleoGMenu8.vue"
import NucleoGTable from "@/components/svg/NucleoGTable.vue"

export default {
  components: {
    LinkRsv,
    Logo5,
    NavMobileSub,
    NucleoOERemove,
    NucleoGMenu8,
    NucleoGTable
  },

  data() {
    return {
      showSub: false
    }
  },

  watch: {
    $route() {
      this.showSub = false
    }
  }
}
</script>

<styles lang="scss">
@use "nav";
@use "svg";
@use "vars";
@use "z-index";

.nav-mobile {
  padding: 0 vars.$body-padding-h;
  background-color: nav.$color-bg;
  position: relative;
}

.nav-mobile__grid {
  display: grid;
  grid-template-columns: 1fr nav.$height 1fr;
  grid-template-rows: nav.$height;
  grid-gap: 1em;
}

.nav-mobile__side {
  display: flex;
  align-items: center;
}

.nav-mobile__side--right {
  justify-content: flex-end;
}

.nav-mobile__iconlink {
  /* Align with other icons. */
  display: block;
  svg {
    display: block;
  }
}

.nav-mobile__nucleo {
  $d: 2em;
  width: $d;
  height: $d;
  cursor: pointer;

  $col: vars.$gold;
  &.nucleo-g-menu-8 {
    @include svg.nucleo-g-table($col);
  }
  &.nucleo-g-table {
    @include svg.nucleo-g-table($col);
  }
  &.nucleo-o-e-remove {
    @include svg.nucleo-o-e-remove($col);
  }
}

.nav-mobile__mid {
  position: relative;
}

.nav-mobile__logowrap {
  position: absolute;
  top: 50%;
  width: 100%;
  height: 100%;
  z-index: z-index.$nav-logo;

  .logo-5 {
    @include svg.logo-5(vars.$gold);
    width: 100%;
    height: 100%;
  }
}

.nav-mobile__logowrap--show-sub {
  /* Hide border around logo */
  background-color: nav.$color-bg;
}
</styles>
