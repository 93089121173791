<!-- External, i.e. not to a route. -->

<template>
  <a
    class="btn-link-ext"
    :href="link.href"
    :target="link.target"
  >
    {{ link.txt }}
  </a>
</template>

<script>
import LinkDataExt from "@/models/LinkDataExt.js"

export default {
  props: {
    link: {
      type: LinkDataExt,
      required: true
    }
  }
}
</script>

<styles lang="scss">
@use "btn";
@use "vars";

.btn-link-ext {
  display: inline-block;
  padding: btn.$padding-v btn.$padding-h;
  background-color: vars.$beige;
  color: vars.$white;
  text-decoration: none;
}
</styles>
