<template>
  <div class="footer-big-cell">
    <div
      class="footer-big-cell__topic"
      :class="{'footer-big-cell__topic--hlight': showUL}"
      @click="showUL = !showUL"
    >
      {{ topic }}
    </div>
    <ul
      v-show="showUL"
      class="footer-big-cell__ul"
    >
      <li
        v-for="x in links"
        :key="x.txt"
      >
        <router-link
          v-if="x.isLinkData"
          :to="x.route"
        >
          {{ x.txt }}
        </router-link>
        <a
          v-if="x.isLinkDataExt"
          :href="x.href"
          :target="x.target"
        >
          {{ x.txt }}
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import LinkData from "@/models/LinkData.js"
import LinkDataExt from "@/models/LinkDataExt.js"

export default {
  props: {
    topic: {
      type: String,
      required: true
    },

    links: {
      type: Array,
      required: true,
      validator: arr => arr.every(x => {
        return x instanceof LinkData || x instanceof LinkDataExt
      })
    }
  },

  data() {
    return {
      showUL: false
    }
  },

  watch: {
    $route() {
      this.showUL = false
    }
  }
}
</script>

<styles lang="scss">
@use "vars";

.footer-big-cell {
  text-align: center;
}

.footer-big-cell__topic {
  font-family: vars.$font-fam-deco;
  font-size: 1.2em;
  text-transform: uppercase;
  cursor: pointer;
}

.footer-big-cell__topic--hlight {
  color: vars.$gold;
}

.footer-big-cell__ul {
  $m: 0.5em;

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 0.5em;
  margin-left: -$m;
  margin-right: -$m;

  li {
    margin: 0 $m;
  }

  a {
    text-decoration: none;
    &.router-link-exact-active {
      color: vars.$gold;
    }
  }
}
</styles>
