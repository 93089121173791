<!-- Renders slot content above moon background. -->

<template>
  <div class="moon-bg">
    <!-- Separate div: img's opacity can be set without affecting content. -->
    <div
      class="moon-bg__img"
      :style="{opacity: moonOpacity}"
    />
    <div class="moon-bg__content">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    moonOpacity: {
      type: Number,
      default: 0.8,
      validator: x => x >= 0 && x <= 1
    }
  }
}
</script>

<styles lang="scss">
@use "bgimg";
@use "position";
@use "z-index";

.moon-bg {
  position: relative;
  border-radius: 50%;
}

.moon-bg__img {
  position: absolute;
  border-radius: inherit;
  @include position.all-zeros;
  @include bgimg.standard("../assets/img/moon_circle.jpg");
}

.moon-bg__content {
  position: relative; /* for z-index */
  z-index: z-index.$moon-bg__content;
  border-radius: inherit;
}
</styles>
