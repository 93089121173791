<template>
  <div class="app-footer">
    <FooterBig
      class="app-footer__big"
      :ticker-txts="tickerTxts"
    />
    <FooterSmall
      class="app-footer__small"
      :ticker-txts="tickerTxts"
    />
  </div>
</template>

<script>
import FooterBig from "@/components/FooterBig.vue"
import FooterSmall from "@/components/FooterSmall.vue"

export default {
  components: {
    FooterBig,
    FooterSmall
  },

  data() {
    return {
      tickerTxts: ["Genuss", "Lebensfreude", "Geschmack", "Design", "Leben"]
    }
  }
}
</script>

<styles lang="scss">
@use "vars";

.app-footer__big {
  @media (max-width: vars.$break-mobile) {
    display: none;
  }
}

.app-footer__small {
  @media (min-width: vars.$break-mobile) {
    display: none;
  }
}
</styles>
