<template>
  <div
    class="intro-screen"
  >
    <!-- Put animation on child - on root it conflicts with <transition>. -->
    <div class="intro-screen__animation">
      <div class="intro-screen__content">
        <div
          class="intro-screen__circle"
          @click="$emit('intro-screen-enter')"
        >
          <div class="intro-screen__bgimg" />
          <div class="intro-screen__txtwrap">
            <Logo5 class="intro-screen__logo-5" />
            <h1 class="intro-screen__slogan">
              Mehr als eine Zahl
            </h1>
            <div class="intro-screen__enterwrap">
              <NounKeyhole />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Logo5 from "@/components/svg/Logo5.vue"
import NounKeyhole from "@/components/svg/NounKeyhole.vue"

export default {
  components: {
    NounKeyhole,
    Logo5
  }
}
</script>

<styles lang="scss">
@use "bgimg";
@use "svg";
@use "vars";
@use "z-index";

.intro-screen {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: z-index.$intro-screen;
  background-color: vars.$beige;
}

.intro-screen__animation {
  height: 100%;
  background-image: url("../assets/img/intro/mod.jpg");
  display: flex;
  align-items: center;
  justify-content: center;
  animation-duration: 100s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-name: move;

  @keyframes move {
    0% {
      // Easing on page load feels to slow.
      animation-timing-function: ease-out;
      background-position: top right;
    }
    25% {
      background-position: bottom left;
    }
    50% {
      background-position: bottom right;
    }
    75% {
      background-position: top left;
    }
    100% {
      background-position: top right;
    }
  }
}

.intro-screen__content {
  padding: 1em;
}

.intro-screen__circle {
  $d: 16em;
  width: $d;
  height: $d;
  padding: 1em;
  overflow: hidden;
  position: relative;
  border-radius: 50%;
}

.intro-screen__bgimg {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  opacity: 0.95;
  @include bgimg.standard("../assets/img/moon_circle.jpg");
}

.intro-screen__txtwrap {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: z-index.$intro-screen__txtwrap;

  /* center content */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: vars.$brown--black;
  cursor: pointer;
}

.intro-screen__logo-5 {
  @include svg.logo-5(vars.$brown--black);
  $d: 5em;
  width: $d;
  height: $d;
  margin-bottom: 1em;
}

.intro-screen__slogan {
  font-size: 1.2em;
  font-weight: bold;
}

.intro-screen__enterwrap {
  margin-top: 1em;

  svg {
    $d: 1.8em;
    width: $d;
    height: auto;
    display: block;
    @include svg.noun-keyhole(vars.$brown--black);
  }
}
</styles>
