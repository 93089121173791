<template>
  <li class="footer-small-topic">
    <div
      class="footer-small-topic__head"
      :class="{'footer-small-topic__head--hlight': showUL}"
      @click="showUL = !showUL"
    >
      {{ topic }}
    </div>
    <ul
      v-show="showUL"
      class="footer-small-topic__ul"
    >
      <li
        v-for="x in links"
        :key="x.txt"
        class="footer-small-topic__li"
      >
        <router-link
          v-if="x.isLinkData"
          :to="x.route"
        >
          {{ x.txt }}
        </router-link>
        <a
          v-if="x.isLinkDataExt"
          :href="x.href"
          :target="x.target"
        >
          {{ x.txt }}
        </a>
      </li>
    </ul>
  </li>
</template>

<script>
import LinkData from "@/models/LinkData.js"
import LinkDataExt from "@/models/LinkDataExt.js"

export default {
  props: {
    topic: {
      type: String,
      required: true
    },

    links: {
      type: Array,
      required: true,
      validator: arr => arr.every(x => {
        return x instanceof LinkData || x instanceof LinkDataExt
      })
    }
  },

  data() {
    return {
      showUL: false
    }
  },

  watch: {
    $route() {
      this.showUL = false
    }
  }
}
</script>

<styles lang="scss">
@use "vars";

.footer-small-topic {
  margin-top: 1em;
  text-align: center;
}

.footer-small-topic__head {
  font-family: vars.$font-fam-deco;
  text-transform: uppercase;
  cursor: pointer;
}

.footer-small-topic__head--hlight {
  color: vars.$gold;
}

.footer-small-topic__li {
  margin-top: 0.5em;

  a {
    text-decoration: none;
    &.router-link-active {
      color: vars.$gold;
    }
  }
}
</styles>
