<template>
  <div class="home">
    <transition name="fade">
      <IntroScreen
        v-if="showIntroScreen"
        @intro-screen-enter="$store.commit('home/setShowIntroScreen', false)"
      />
    </transition>
    <div class="wrap-img-hero">
      <!-- Render after hiding intro screen, to show user 1st pic then. -->
      <ImgHero
        v-if="!showIntroScreen"
        class="img-hero"
        :img-arr="imgHeroImgs"
      />
    </div>

    <div class="txt">
      <Logo5 class="logo" />
      <TickerTxt
        :txts="['Sinne', 'Elemente', 'Kontinente']"
        :style="{marginTop: '1em'}"
      />
    </div>

    <div class="txt">
      <h1 class="header">
        Mehr als eine Zahl
      </h1>
      <p>
        5 ist mehr als ein Gourmet Restaurant, mehr als eine Bar, mehr als eine Lounge, mehr als ein Café.
      </p>
      <p>
        5 ist Genuss, ist Lebensfreude, ist Geschmack, ist Design, ist Leben.
      </p>
    </div>

    <div class="txt">
      <h2 class="header">
        Leidenschaft für das Besondere
      </h2>
      <p>
        Eine Oase der Inspiration und Entspannung, ein kosmopolitischer Platz,
      </p>
      <p>
        an dem kulinarischer Genuss und Ausgehen eins werden.
      </p>
    </div>

    <div class="txt">
      <h2 class="header">
        Reservieren
      </h2>
      <p>
        Reservieren Sie im 5 Gourmet Restaurant (1. OG) oder im 5 Bar Restaurant (EG).
      </p>
      <div class="link-btn-wrap">
        <BtnRsv>
          Zur Reservierung
        </BtnRsv>
      </div>
    </div>

    <ImgCombo
      class="img-combo"
      :img-data="imgComboData.food"
    />

    <div class="txt">
      <h1 class="header">
        Aussergewöhnlich Anders
      </h1>
      <p>
        Ob Dinner im 5 Gourmet Restaurant im Obergeschoss, Frühstück, Lunch , ein kleines Feinschmecker oder Asia/Sushi Menu im 5 Bar/Restaurant im Erdgeschoss
      </p>
      <p>
        - Im 5 machen alle Disziplinen Spaß -
      </p>
    </div>

    <div class="txt">
      <h2 class="header">
        Ein kosmopolitischer Platz für alle Sinne
      </h2>
      <p>
        Das 5 eine Oase der Inspiration und Entspannung.
      </p>
      <p>
        Mit unserem Küchenchef Alexander Dinter und seinem Menu „Passion“
      </p>
      <p>
        erleben Sie in den Räumlichkeiten des ersten Stuttgarter Bahnhofs Genuss für Augen und Gaumen auf höchstem Niveau.
      </p>
    </div>

    <div class="txt">
      <h1 class="header">
        Die Nr. 1 bei TripAdvisor Kundenbewertungen
      </h1>
      <p>
        Im dritten Jahr in Folge ist das 5 laut TripAdvisor (TripAdvisor Traveler Choice Award 2019, 2020 und 2021)
      </p>
      <p>
        die Nr.1 bei den gehobenen Restaurants in Deutschland und die Nr. 11 auf der Welt.
      </p>
      <div class="link-btn-wrap">
        <BtnLinkExt :link="linkData.tripadvisor" />
      </div>
    </div>
  </div>
</template>

<script>
import BtnLinkExt from "@/components/BtnLinkExt.vue"
import BtnRsv from "@/components/BtnRsv.vue"
import ImgCombo from "@/components/ImgCombo.vue"
import ImgData from "@/models/ImgData.js"
import ImgHero from "@/components/ImgHero.vue"
import IntroScreen from "@/components/IntroScreen.vue"
import LinkDataExt from "@/models/LinkDataExt.js"
import Logo5 from "@/components/svg/Logo5.vue"
import {mapState} from "vuex"
import TickerTxt from "@/components/TickerTxt.vue"

export default {
  name: "Home",

  components: {
    BtnLinkExt,
    BtnRsv,
    ImgCombo,
    ImgHero,
    IntroScreen,
    Logo5,
    TickerTxt
  },

  data() {
    return {
      imgComboData: {
        food: [
          new ImgData("/img/2021_05_27/index_food_2.jpg", "Vorspeise"),
          new ImgData("/img/2021_05_27/index_food_1.jpg", "Hauptgang")
        ]
      },
      imgHeroImgs: [
        new ImgData("/img/essraum1.jpg", "", {slogan: "Augenblick"}),
        new ImgData("/img/essraum2.jpg", "", {slogan: "Leben"}),
        new ImgData("/img/wohnraum3.jpg", "", {slogan: "Leidenschaft"}),
        new ImgData("/img/food1.jpg", "", {slogan: "Anders"}),
        new ImgData("/img/food2.jpg", "", {slogan: "Frei"})
      ],
      linkData: {
        tripadvisor: new LinkDataExt({
          txt: "TripAdvisor Traveler Choice Award",
          href: "https://www.tripadvisor.de/TravelersChoice-Restaurants-cFineDining-g187275",
          target: "_blank"
        })
      }
    }
  },

  computed: {
    ...mapState({
      showIntroScreen: state => state.home.showIntroScreen
    })
  }
}
</script>

<style lang="scss" scoped>
@use "img";
@use "nav";
@use "trans";
@use "svg";
@use "txt";
@use "vars";

@mixin hugetxt {
  font-size: 1.5em;
  color: vars.$gold;
  text-transform: uppercase;
}

.home {
  padding-bottom: txt.$blockmargin;

  .intro-screen {
    @include trans.fade(1.5s);
  }

  .ticker-txt::v-deep {
    .ticker-txt__txt {
      @include hugetxt;
    }
  }
}

.wrap-img-hero {
  /*
    Workaround missing support for vh/vw in calc in some safari versions.
    See https://stackoverflow.com/a/21279146
  */
  height: 100vh;
  min-height: 30em;
  margin-top: -(nav.$height);
  padding-top: nav.$height;
}

.img-hero {
  height: 100%;
  border-top: 0;
}

.txt {
  margin-top: txt.$blockmargin;
  @include txt.bodypadding-h;
  text-align: center;

  p {
    margin-top: 1em;
    &:first-child {
      margin-top: 0;
    }
  }
}

.link-btn-wrap {
  margin-top: 2em;
}

.logo {
  &.logo-5::v-deep {
    display: block;
    margin: 0 auto;
    $d: 5em;
    width: $d;
    height: $d;
    @include svg.logo-5(vars.$gold);
  }
}

.header {
  @include hugetxt;
}

.img-combo {
  margin: txt.$blockmargin 0;
}
</style>
