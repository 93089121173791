<!-- Unstyled - to be styled by consumers. -->

<template>
  <div class="ticker-txt">
    <transition
      name="fade"
      mode="out-in"
    >
      <!-- Without :key the rendered __txt would be re-used (no transition). -->
      <div
        :key="currTxtIdx"
        class="ticker-txt__txt"
      >
        {{ currTxt }}
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    interval: {
      type: Number, // ms
      default: 2500
    },

    txts: {
      type: Array, // String[]
      required: true,
      validator(val) {
        return val.length > 0
      }
    }
  },

  data() {
    return {
      currTxtIdx: 0
    }
  },

  computed: {
    currTxt() {
      return this.txts[this.currTxtIdx]
    }
  },

  mounted() {
    window.setInterval(() => this.switchTxt(), this.interval)
  },

  methods: {
    switchTxt() {
      if (this.currTxtIdx === this.txts.length - 1) {
        this.currTxtIdx = 0
      } else {
        this.currTxtIdx++
      }
    }
  }
}
</script>

<styles lang="scss">
@use "trans";
@use "vars";

.ticker-txt {
  font-family: vars.$font-fam-deco;
}

.ticker-txt__txt {
  @include trans.fade;
}
</styles>
