<!-- Shrinks to height of smallest img. -->

<template>
  <div class="img-combo">
    <div class="img-combo__imgwrap">
      <ImgCut
        v-for="(x,i) in imgData"
        :key="x.href"
        :alt="x.alt"
        :src="x.src"
        class="img-combo__img"
        :class="{'img-combo__img--first': i === 0, 'img-combo__img--last': i === imgData.length - 1}"
        :want-ratio="maxRatio"
        @img-cut-newratio="onChildRatioChange"
      />
    </div>
  </div>
</template>

<script>
import ImgCut from "@/components/ImgCut.vue"
import ImgData from "@/models/ImgData.js"

export default {
  components: {
    ImgCut
  },

  props: {
    imgData: {
      type: Array,
      required: true,
      validator: arr => arr.every(x => x instanceof ImgData)
    },
    wantRatio: {
      type: Number,
      default: 3 / 2 // most frequent ratio of 5 pics
    }
  },

  data() {
    return {
      maxRatio: this.wantRatio
    }
  },

  methods: {
    onChildRatioChange(newVal) {
      if (newVal > this.maxRatio) {
        // Cause all img's height to shrink.
        this.maxRatio = newVal
      }
    }
  }
}
</script>

<styles lang="scss">
@use "sass:math";

@use "img";
@use "vars";

.img-combo__imgwrap {
  display: flex;
  background-color: img.$gap__color;

  @media (max-width: vars.$break-mobile) {
    flex-direction: column;
  }
}

.img-combo__img {
  flex: 1 1 0%;
  margin: img.$gap math.div(img.$gap, 2);

  @media (max-width: vars.$break-mobile) {
    margin: math.div(img.$gap, 2) 0; /* margins inside flex don't collapse */
  }
}

.img-combo__img--first {
  @media (min-width: vars.$break-mobile) {
    margin-left: 0;
  }
  @media (max-width: vars.$break-mobile) {
    margin-top: img.$gap;
  }
}

.img-combo__img--last {
  @media (min-width: vars.$break-mobile) {
    margin-right: 0;
  }
  @media (max-width: vars.$break-mobile) {
    margin-bottom: img.$gap;
  }
}
</styles>
