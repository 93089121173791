<template>
  <div class="img-hero">
    <transition
      name="fade"
      mode="in-out"
    >
      <!-- Without :key div would be re-used (no transition). -->
      <div
        :key="shownImg.src"
        class="img-hero__bgimg"
        :style="{backgroundImage: `url('${shownImg.src}')`}"
      />
    </transition>
    <ImgHeroOverlay
      class="img-hero__overlay"
      :num-imgs="imgArr.length"
      :shown-idx="shownImgIdx"
      :slogan="shownImg.opts.slogan"
      @img-hero-overlay-selectidx="onSelectidx"
    />
  </div>
</template>

<script>
import {fwdIdxCircular} from "@/util/array.js"
import ImgData from "@/models/ImgData.js"
import ImgHeroOverlay from "@/components/ImgHeroOverlay.vue"

export default {
  components: {
    ImgHeroOverlay
  },

  props: {
    imgArr: {
      type: Array,
      required: true,
      validator: arr => arr.every(x => x instanceof ImgData)
    },
    interval: {
      type: Number, // miliseconds
      default: 5000
    }
  },

  data() {
    return {
      intervalID: undefined,
      shownImgIdx: 0
    }
  },

  computed: {
    shownImg() {
      return this.imgArr[this.shownImgIdx]
    }
  },

  created() {
    this.setInterval()
  },

  methods: {
    fwdShownImgIdx() {
      this.shownImgIdx = fwdIdxCircular(this.shownImgIdx, 1, this.imgArr.length)
    },

    onSelectidx(i) {
      // Reset interval after user interaction.
      // Avoid immediate auto pic-change after showing user's selection.
      this.setInterval()
      this.shownImgIdx = i
    },

    setInterval() {
      if (this.intervalID) {
        window.clearInterval(this.intervalID)
      }
      this.intervalID = window.setInterval(() => {
        this.fwdShownImgIdx()
      }, this.interval)
    }
  }
}
</script>

<styles lang="scss">
@use "bgimg";
@use "img";
@use "img-hero";
@use "position";
@use "trans";
@use "vars";

.img-hero {
  position: relative;
  $b: img.$gap solid vars.$beige;
  border-top: $b;
  border-bottom: $b;
  overflow: hidden;
}

.img-hero__bgimg {
  position: absolute;
  @include position.all-zeros;
  @include bgimg.standard;
  @include trans.fade($t: img-hero.$trans-fade-t);
}

.img-hero__overlay {
  position: absolute;
  @include position.all-zeros;
}
</styles>
