<!--
  ImgCut cuts from bottom of img if ratio > above wantRatio (i.e. more square).
  If ratio < wantRatio, ImgCut shrinks to fit img.

  Reasoning:
  * 5's image not suited for background-image (i.e. for cutting from side(s))
  * Side-effect: <img> is better for SEO than background-image
-->

<template>
  <div class="img-cut">
    <!--
      Use __imgwrap to avoid using percentage pattern on root element.
      Otherwise ImgCut can't be used in flexbox, see
      https://stackoverflow.com/a/36783414
    -->
    <div
      class="img-cut__imgwrap"
      :style="{paddingBottom: paddingBottom}"
    >
      <img
        class="img-cut__img"
        :src="src"
        :alt="alt"
        @load="onImgLoad"
      >
    </div>
  </div>
</template>

<script>
export default {
  props: {
    alt: {
      type: String,
      required: true
    },
    wantRatio: {
      type: Number,
      default: 3 / 2 // most frequent ratio of 5 pics
    },
    src: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      ratio: this.wantRatio
    }
  },

  computed: {
    paddingBottom() {
      return `${(1 / this.ratio) * 100}%`
    }
  },

  watch: {
    wantRatio(newVal, oldVal) {
      if (newVal > oldVal) {
        // Not emitting img-cut-newratio as change was triggered by parent.
        this.ratio = newVal
      }
    }
  },

  methods: {
    /* @param {Object} e - img onload event */
    onImgLoad({target: {naturalHeight: h, naturalWidth: w}}) {
      if (h === 0 || w === 0) {
        this.ratio = 0 // failed to load img
        return
      }
      const r = w / h
      if (r > this.wantRatio) {
        this.ratio = r
        this.$emit("img-cut-newratio", r)
      }
    }
  }
}
</script>

<style lang="scss">
.img-cut__imgwrap {
  position: relative;
  overflow: hidden;
}

.img-cut__img {
  position: absolute;
  width: 100%;
  height: auto;
}
</style>
