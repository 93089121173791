<template>
  <div class="nav-mobile-sub">
    <NavMobileTopics
      v-show="!selectedTopic"
      :topics="topics"
      @nav-mobile-topics-select="selectTopic"
    />
    <NavMobileLinks
      v-show="selectedTopic"
      :topic="selectedTopic"
      :links="selectedTopicLinks"
      @nav-mobile-links-cleartopic="selectTopic('')"
    />
  </div>
</template>

<script>
import NavMobileLinks from "@/components/NavMobileLinks.vue"
import NavMobileTopics from "@/components/NavMobileTopics.vue"
import {navLinks, navTopics} from "@/models/LinkData.js"

export default {
  components: {
    NavMobileLinks,
    NavMobileTopics
  },

  data() {
    return {
      links: navLinks,
      topics: navTopics,
      selectedTopic: ""
    }
  },

  computed: {
    selectedTopicLinks() {
      const t = this.selectedTopic
      return t ? this.links[t] : []
    }
  },

  methods: {
    selectTopic(t) {
      this.selectedTopic = t
    }
  }
}
</script>

<styles lang="scss">
@use "nav";
@use "z-index";

.nav-mobile-sub {
  position: absolute;
  top: nav.$height;
  left: 0;
  right: 0;
  z-index: z-index.$nav;
  background-color: nav.$color-bg;
  border-top: 1px solid nav.$color;
}
</styles>
