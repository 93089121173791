<!-- TODO use <router-link> instead of <a> -->
<template>
  <div class="nav-bar-sub">
    <div class="nav-bar-sub__head">
      <h1>{{ title }}</h1>
    </div>
    <ul class="nav-bar-sub__ul">
      <li
        v-for="x in links"
        :key="x.txt"
        class="nav-bar-sub__li"
      >
        <router-link
          v-if="x.isLinkData"
          :to="x.route"
        >
          {{ x.txt }}
        </router-link>
        <a
          v-if="x.isLinkDataExt"
          :href="x.href"
          :target="x.target"
        >
          {{ x.txt }}
        </a>
      </li>
      <li class="nav-bar-sub__li nav-bar-sub__closewrap">
        <NucleoGERemove
          @click.native="$emit('nav-bar-sub-close')"
        />
      </li>
      <li class="nav-bar-sub__li nav-bar-sub__reserve">
        <LinkRsv />
      </li>
    </ul>
  </div>
</template>

<script>
import LinkData from "@/models/LinkData.js"
import LinkDataExt from "@/models/LinkDataExt.js"
import LinkRsv from "@/components/LinkRsv.vue"
import NucleoGERemove from "@/components/svg/NucleoGERemove.vue"

export default {
  components: {
    LinkRsv,
    NucleoGERemove
  },

  props: {
    title: {
      type: String,
      required: true
    },
    links: {
      type: Array,
      required: true,
      validator: arr => arr.every(x => {
        return x instanceof LinkData || x instanceof LinkDataExt
      })
    }
  }
}
</script>

<styles lang="scss">
@use "nav";
@use "svg";
@use "vars";
@use "z-index";

.nav-bar-sub {
  position: absolute;
  top: nav.$height;
  left: 0;
  right: 0;
  z-index: z-index.$nav;
  padding: 3em vars.$body-padding-h 2em vars.$body-padding-h;
  border-top: 1px solid;
  background-color: nav.$color-bg;
  font-family: vars.$font-fam-deco;
}

$head-fs: 1.2em;

.nav-bar-sub__head {
  text-align: center;
  color: vars.$gold;
  text-transform: uppercase;
}

.nav-bar-sub__ul {
  margin-top: 1em;
  text-align: center;
}

.nav-bar-sub__li {
  margin-top: 0.5em;

  a {
    text-decoration: none;
    text-transform: uppercase;
    &.router-link-exact-active {
      color: vars.$gold;
    }
  }
}

.nav-bar-sub__closewrap {
  margin-top: 1.5em;
  display: flex;
  align-items: center;
  justify-content: center;

  .nucleo-g-e-remove {
    $d: 1.5em;
    width: $d;
    height: $d;
    cursor: pointer;
    @include svg.nucleo-g-e-remove(vars.$white);
  }
}

.nav-bar-sub__reserve {
  margin-top: 1.5em;
}
</styles>
